import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Interviews',
      },
      {
        color: 'warning',
        label: 'Events',
      },
      {
        color: 'info',
        label: 'Meetings',
      },
    ],
    selectedCalendars: ['Interviews', 'Events', 'Meetings'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, queryParams) {
      /* const date = new Date()
      const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      // prettier-ignore
      const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
      // prettier-ignore
      const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)
      console.log(date, nextDay)
      const data = {
        events: [
          {
            id: 1,
            url: '',
            title: 'Design Review',
            start: date,
            end: nextDay,
            allDay: false,
            extendedProps: {
              calendar: 'Interviews',
            },
          },
          {
            id: 2,
            url: '',
            title: 'Meeting With Client',
            start: date,
            end: nextDay,
            allDay: false,
            extendedProps: {
              calendar: 'Interviews',
            },
          },
          {
            id: 3,
            url: '',
            title: 'Family Trip',
            allDay: false,
            start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -7),
            extendedProps: {
              calendar: 'Interviews',
            },
          },
          {
            id: 4,
            url: '',
            title: "Doctor's Appointment",
            start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
            allDay: false,
            extendedProps: {
              calendar: 'Interviews',
            },
          },
          {
            id: 5,
            url: '',
            title: 'Dart Game?',
            start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
            allDay: false,
            extendedProps: {
              calendar: 'Interviews',
            },
          },
          {
            id: 6,
            url: '',
            title: 'Meditation',
            start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
            allDay: false,
            extendedProps: {
              calendar: 'Interviews',
            },
          },
          {
            id: 7,
            url: '',
            title: 'Dinner',
            start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
            allDay: false,
            extendedProps: {
              calendar: 'Interviews',
            },
          },
          {
            id: 8,
            url: '',
            title: 'Product Review',
            start: new Date(date.getFullYear(), date.getMonth() + 1, -13),
            end: new Date(date.getFullYear(), date.getMonth() + 1, -12),
            allDay: false,
            extendedProps: {
              calendar: 'Interviews',
            },
          },
          {
            id: 9,
            url: '',
            title: 'Monthly Meeting',
            start: nextMonth,
            end: nextMonth,
            allDay: false,
            extendedProps: {
              calendar: 'Interviews',
            },
          },
          {
            id: 10,
            url: '',
            title: 'Monthly Checkup',
            start: prevMonth,
            end: prevMonth,
            allDay: false,
            extendedProps: {
              calendar: 'Interview',
            },
          }],
      }
      return data */
      return new Promise((resolve, reject) => {
        axios
          .get('/calendar-interview', { params: queryParams })
          .then(response => {
            const date = {
              events: [],
            }
            response.data.data.events.forEach(item => {
              date.events.push({
                allDay: item.allDay,
                end: new Date(Date.parse(item.end_at_utc)),
                extendedProps: { calendar: item.extendedProps.calendar },
                id: item.id,
                start: new Date(Date.parse(item.start_at_utc)),
                title: item.title,
                url: '',
              })
            })
            resolve(date)
          })
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
